import {useEffect, useRef, useState} from "react";
import api from "../../Helpers/api-helper";
import {Button, Form, Input} from "antd";
import {FolderOpenOutlined} from "@ant-design/icons";
import {notify} from "../../Helpers/toast-helper";
import {IconPaste} from "../Icons";

const RequestClone = ({open, setOpen, setData}) => {
    const inputRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleSearch = (e) => {
        const fieldsToDelete = ['request_code', 'created_at', 'created_by', 'created_by_name', 'created_department', 'created_position', 'updated_at', 'updated_by', 'request_index', 'approval_status', 'request_payment_date']
        const value = e.target.value?.trim() || inputRef.current.input.defaultValue?.trim();

        if (!value || (value.length !== 14)) {
            notify('error', 'Mã đề nghị không hợp lệ')
            return;
        }

        setLoading(true);

        api.post(`/request/user/get`, {request_code: value}).then((response) => {
            if (response) {
                fieldsToDelete.forEach((field) => {
                    delete response[field]
                })

                response.request_status = 'new'

                setData(response);
                form.resetFields()
                setOpen(false);
            }
        }).finally(() => {
            setLoading(false)
        });
    }

    useEffect(() => {
        if (!open) {
            setLoading(false)
            form.resetFields()
        }

        if (open) {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 100);
        }
    }, [open]);

    return (
        <Form
            form={form}
        >
            <Form.Item
                name="search"
                style={{marginBottom: 0}}
            >
                <Input
                    ref={inputRef}
                    placeholder="Mã đề nghị"
                    style={{maxWidth: 250}}
                    prefix={<FolderOpenOutlined/>}
                    suffix={<Button loading={loading} size="small" type="text" onClick={handleSearch} icon={<IconPaste />}/>}
                    onPressEnter={handleSearch}
                    autoFocus={true}
                    allowClear={true}
                />
            </Form.Item>
            <div style={{width: 250}} className="mt-2">
                Sao chép lại nội dung của một đề nghị thanh toán đã tạo. Lưu ý: Bạn chỉ có thể lấy lại nội dung của đề nghị thanh toán đã tạo bởi chính mình.
            </div>
        </Form>
    )
}

export default RequestClone