import {Button, Modal, Space} from "antd";
import {IconSpeaker} from "../Icons";
import {useEffect, useState} from "react";
import api from "../../Helpers/api-helper";
import {compareVersions} from "compare-versions";
import {HistoryOutlined, ReloadOutlined} from "@ant-design/icons";

const VersionChecker = ({appVersion}) => {
    const [versionConfirm, setVersionConfirm] = useState(false);
    const VERSION_INTERVAL = 15 * 60 * 1000; //15 minutes

    const fetchVersion = async () => {
        api.get('/version')
            .then((response) => {
                if (response) {
                    const {version} = response || null;
                    if (!version) {
                        return;
                    }

                    if (compareVersions(appVersion, version) < 0) {
                        //Show a dialog to user to refresh the page
                        setVersionConfirm(true);
                    }
                }
            })
            .catch((error) => {
                console.log("Error getting version from API: ", error.message);
            });
    }

    useEffect(() => {
        fetchVersion();

        const interval = setInterval(() => {
            fetchVersion();
        }, VERSION_INTERVAL);

        return () => clearInterval(interval);
    }, [])

    const handleCancel = () => {
        setVersionConfirm(false);
    }

    return (
        <Modal
            open={versionConfirm}
            onCancel={handleCancel}
            title="Cập nhật phiên bản mới"
            maskClosable={false}
            footer={
                <Space>
                    <Button
                        icon={<HistoryOutlined />}
                        onClick={handleCancel}
                    >
                        Để sau
                    </Button>
                    <Button
                        type="primary"
                        icon={<ReloadOutlined />}
                        onClick={() => window.location.reload()}
                    >
                        Cập nhật ngay
                    </Button>
                </Space>
            }
        >
            <Space>
                <IconSpeaker style={{width: 42}} className="m-3"/>
                <span>Có phiên bản mới, vui lòng nhấn "Cập nhật ngay" để cập nhật phiên bản mới nhất.</span>
            </Space>
        </Modal>
    )
}

export default VersionChecker;