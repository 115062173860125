import {Button, ConfigProvider, Flex, Table} from "antd";
import {DeleteOutlined, PrinterOutlined} from "@ant-design/icons";
import "./PrintForm.css"
import {numberFormat} from "../../Helpers/common-helper";

export const tranfrormData = (lineData) => {
    //Return if lineData is empty
    if (lineData.length === 0) {
        return []
    }

    const isVND = lineData[0].currency_code === 'VND'

    //Sort the lineData by expense_type
    //Then add a summary line to the end of each expense

    //Get the list of lineData with expense_type is not null
    const filteredData = lineData.filter(item => item.expense_type !== null)

    //Sort the lineData by expense_type
    let sortedData = filteredData.sort((a, b) => {
        return a.expense_type.toString().localeCompare(b.expense_type)
    })

    //Get a list of unique expense_type ans set the order ascending by oldest timestamp of each expense_type
    //First, get the unique expense_type list
    let uniqueTypes = [...new Set(sortedData.map(item => item.expense_type))]

    //Get the oldest timestamp of each expense_type by oldest item with that expense_type
    uniqueTypes = uniqueTypes.sort((a, b) => {
        let aTimestamp = sortedData.find(item => item.expense_type === a).timestamp
        let bTimestamp = sortedData.find(item => item.expense_type === b).timestamp
        return aTimestamp - bTimestamp
    })

    let processingData = []
    let result = []
    //Add a summary line to the end of each expense
    uniqueTypes.forEach((type) => {
        //Get all items with the same expense_type and push to the processingData
        let items = sortedData.filter(item => item.expense_type === type)
        processingData = processingData.concat(items)

        let payment_amount = sortedData.filter(item => item.expense_type === type)
            .reduce((acc, item) => acc + Number(item.amount), 0)

        let total_amount = sortedData.filter(item => item.expense_type === type)
            .reduce((acc, item) => acc + Number(item.total_amount), 0)

        processingData.push({
            key: 'summary_' + type,
            description: `Tổng ${type}`,
            //Round the total_amount to 2 decimal places
            //If the currency is VND, remove the decimal places
            total_amount: isNaN(total_amount) ? 0 : Number(total_amount.toFixed(isVND ? 0 : 2)),
            amount: isNaN(payment_amount) ? 0 : Number(payment_amount.toFixed(isVND ? 0 : 2)),
            currency_code: sortedData[0].currency_code,
            note: '',
            poCode: '',
        })
    })

    //Re-order the processingData by timestamp of each summary line
    //Get all the summary lines
    let summaryLines = processingData.filter(item => item.key.toString().startsWith('summary'))
    //Sort the summaryLines by timestamp ascending
    summaryLines = summaryLines.sort((a, b) => a.timestamp - b.timestamp)
    //Push the lines to the processingData based on the order of the summaryLines, and put the summaryLines at the end
    summaryLines.forEach((summary) => {
        //Get all lines with the same expense_type
        let lines = processingData.filter(item => item?.expense_type && item.expense_type.toString().endsWith(summary.key.split('_')[1]))
        result = result.concat(lines)
        result.push(summary)
    })


    //Add index to each item
    let index = 1
    result.forEach((item) => {
        //Only add index to the item with timestamp
        if (item.timestamp)
            item.index = index++
    })

    //If the number of expense_type is greater than 1, add a summary line to the end of the table
    if (uniqueTypes.length > 1) {

        let payment_total = result.reduce((acc, item) => {
            if (item.key.toString().startsWith('summary'))
                return acc + item.amount
            return acc
        }, 0)

        let total_amount = result.reduce((acc, item) => {
            if (item.key.toString().startsWith('summary'))
                return acc + item.total_amount
            return acc
        }, 0)

        result.push({
            key: 'summary_total',
            description: 'Tổng cộng',
            amount: payment_total,
            total_amount : isNaN(total_amount) ? null : total_amount,
            currency_code: result[0].currency_code,
            note: '',
            poCode: '',
        })
    }

    return result
}

export const rowHelper = (record) => {
    if (record.key.toString().startsWith('summary')) {
        return {
            style: {backgroundColor: record.key === 'summary_total' ? '#f0f0f0' : '#fafafa', fontWeight: 'bold'}
        }
    }
}

export const line_columns = (lineData, deleteALine, isPrint = false) => [
    {
        title: 'STT',
        dataIndex: 'key',
        key: 'key',
        width: "50px",
        align: 'center',
        onCell: (row) => {
            if (row.key.toString().startsWith('summary')) {
                return {
                    colSpan: 2
                }
            }
        },
        render: (text, record) => {
            if (text.toString().startsWith('summary')) {
                let summaryType = text.toString().split('_')
                //Get the list of previous items with the same expense_type
                let expenseType = text.toString().split('_')[1]
                let previousItems = lineData.filter(item => item.expense_type === expenseType)
                //Get the key list of previous items
                let previousKeys = previousItems.map(item => item.index)

                //if previousKeys has more than 7 items, only return first 3 and last 3 items, and add '...' in the middle
                if (previousKeys.length >= 7) {
                    previousKeys = previousKeys.slice(0, 3).concat(['...']).concat(previousKeys.slice(-3))
                }

                return (summaryType[1] === 'total') ? `Tổng cộng` : `Tổng (${previousKeys.join(' + ')})`
            } else {
                return record.index
            }
        }
    },
    {
        title: 'Diễn giải',
        dataIndex: 'description',
        key: 'description',
        width: "40%",
        onCell: (row) => {
            if (row.key.toString().startsWith('summary')) {
                return {
                    colSpan: 0
                }
            }
        }
    },
    {
        title: 'Số lượng',
        dataIndex: "quantity",
        key: "quantity",
        align: 'right',
        render: (text, record) => {
            return numberFormat(text, '')
        }
    },
    {
        title: 'Đơn giá',
        dataIndex: "price",
        key: "price",
        align: 'right',
        render: (text, record) => {
            return numberFormat(text, '')
        }
    },
    {
        title: 'Thành tiền',
        dataIndex: 'total_amount',
        key: 'total_amount',
        align: 'right',
        render: (text, record) => {
            return (
                <div>
                    {text?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </div>
            )
        }
    },
    {
        title: 'Thanh toán',
        dataIndex: 'amount',
        key: 'amount',
        align: 'right',
        render: (text, record) => {
            return (
                <div>
                    {text?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </div>
            )
        }
    },
    {
        title: '%',
        dataIndex: 'payment_percentage',
        key: 'payment_percentage',
        align: 'center',
        render: (text, record) => {
            return (
                <span>{text ? `${text}%` : ''}</span>
            )
        }
    },
    {
        title: 'Loai tiền',
        dataIndex: 'currency_code',
        key: 'currency',
        align: 'center',
        hidden: true
    },
    {
        title: 'Ghi chú',
        dataIndex: 'note',
        key: 'type',
    },
    {
        title: 'PO',
        dataIndex: 'poCode',
        key: 'poCode',
        align: 'center',
        width: '10%'
    },
    {
        title: '#',
        key: 'extra',
        render: (item, row) => {
            if (item.key.toString().startsWith('summary'))
                return null

            return (
                <Button
                    danger
                    size="small"
                    type="link"
                    title='Xóa dòng này'
                    onClick={() => deleteALine(row.index)}
                >
                    <DeleteOutlined/>
                </Button>
            )
        },
        width: "40px",
        align: 'center',
        hidden: !deleteALine
    },
    {
        title: ' ',
        key: 'poCode',
        width: "0px",
        hidden: true,
        dataIndex: 'poCode'
    }
]

export const PaymentLineTable = ({lineData, print = false, showPrintButton}) => {
    return lineData?.length > 0 ? (
        // Render something if payment_lines exist and have items
        <div className="mt-3">
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            borderColor: '#000000'
                        },
                    },
                }}
            >
                <Table
                    rowClassName={print ? 'formFont table-dark-border' : 'compact-table'}
                    title={() => print ? <span className="formFont">Đề nghị thanh toán theo bảng dưới đây</span> :
                        <Flex
                            justify="space-between"
                        >
                            <strong>Bảng kê chi phí</strong>
                            {showPrintButton && showPrintButton}
                        </Flex>
                    }
                    onHeaderRow={(column) => {
                        return {
                            className: print ? 'formFont' : ''
                        }
                    }}
                    columns={line_columns(lineData, false, true)}
                    dataSource={tranfrormData(lineData)}
                    onRow={(record) => {
                        return rowHelper(record)
                    }}
                    bordered
                    pagination={false}
                    size={print ? 'small' : 'normal'}
                />
            </ConfigProvider>
        </div>
    ) : null
}

export function removeVietnameseAccents(str) {
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    str = str.replace(/đ/g, "d");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư

    return str;
}

export function parseJSON(jsonString) {
    try {
        return JSON.parse(jsonString)
    } catch (e) {
        return []
    }
}

export function stringify(json) {
    try {
        return JSON.stringify(json)
    } catch (e) {
        return ''
    }
}