import React, {useEffect, useRef, useState} from 'react';
import {ClearOutlined, PlusOutlined} from '@ant-design/icons';
import {Flex, Input, Tag, theme, Tooltip} from 'antd';

const tagInputStyle = {
    width: 64,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: 'top',
};
const POTags = ({tags, setTags}) => {
    const {token} = theme.useToken();
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [editInputValue]);
    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        setTags(newTags);
    };
    const showInput = () => {
        setInputVisible(true);
    };
    const handleInputChange = (e) => {
        const value = e.target.value.toString().toUpperCase().trim()
        setInputValue(value);
    };
    const handleInputConfirm = () => {

        //Determine if the input is multiple tags, separated by comma or space
        //Split the input value by comma or space
        //Trim the value and convert to uppercase
        //Make the value unique
        //Add to the tags array

        const values = inputValue.toString().split(/[, \n]+/).map((tag) => tag.trim().toUpperCase()).filter((tag) => tag.length > 0);

        if (values.length > 0) {
            const newTags = [...tags];
            values.forEach((value) => {
                if (!newTags.includes(value)) {
                    //Sanitize the value to accept only letters and numbers
                    value = value.replace(/[^A-Z0-9]/g, '');
                    newTags.push(value);
                }
            });

            setTags(newTags);
        }

        setInputVisible(false);
        setInputValue('');

    };
    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value.toString().substring(0, 20).toUpperCase().trim());
    };
    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        setTags(newTags);
        setEditInputIndex(-1);
        setEditInputValue('');
    };

    const clearTags = () => {
        setTags([]);
    }
    const tagPlusStyle = {
        height: 22,
        background: token.colorBgContainer,
        borderStyle: 'dashed',
    };

    return (
        <Flex gap="4px 0" wrap>
            {tags.map((tag, index) => {
                if (editInputIndex === index) {
                    return (
                        <Input
                            ref={editInputRef}
                            key={tag}
                            size="small"
                            style={tagInputStyle}
                            value={editInputValue}
                            onChange={handleEditInputChange}
                            onBlur={handleEditInputConfirm}
                            onPressEnter={handleEditInputConfirm}
                        />
                    );
                }
                const isLongTag = tag.length > 20;
                const tagElem = (
                    <Tag
                        key={tag}
                        closable={true}
                        style={{
                            userSelect: 'none',
                        }}
                        onClose={() => handleClose(tag)}
                    >
            <span
                onDoubleClick={(e) => {
                    //Disable edit mode
                    return
                    /*
                    setEditInputIndex(index);
                    setEditInputValue(tag);
                    e.preventDefault();
                     */
                }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
                    </Tag>
                );
                return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                        {tagElem}
                    </Tooltip>
                ) : (
                    tagElem
                );
            })}
            {inputVisible ? (
                <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            ) : (
                <>
                    <Tag style={tagPlusStyle} icon={<PlusOutlined/>} onClick={showInput}/>
                    {/*
                    <Tag style={tagPlusStyle} icon={<ClearOutlined/>} onClick={clearTags}/>
                    */}
                </>
            )}
        </Flex>
    );
};

export default POTags;