import React from 'react';
import ReactDOM from 'react-dom/client';
import './fonts.css';
import './index.css';
import App from './App';
import {ConfigProvider, Divider} from "antd";
import toast, {ToastBar, Toaster} from "react-hot-toast";
import {CloseOutlined} from "@ant-design/icons";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <Toaster>
            {(t) => (
                <ToastBar toast={t}>
                    {({icon, message}) => {
                        return (
                            <>
                                {icon}
                                {message}
                                <Divider type="vertical" className="toast-box-separator"/>
                                {t.type !== 'loading' && (
                                    <CloseOutlined
                                        className="pointer-hand"
                                        onClick={() => toast.dismiss(t.id)}
                                    />
                                )}
                            </>
                        )
                    }}
                </ToastBar>
            )}
        </Toaster>
        <ConfigProvider
            theme={{
                token: {
                    screenLGMin: 1000,
                    screenLG: 1000,
                    borderRadius: 10,
                    controlHeight: 34,
                    fontFamily: 'Google Sans,Roboto,Arial,sans-serif; !important',
                    colorPrimary: '#5f81ff',
                    colorError: '#F2479B',
                },
            }}
        >
            <App/>
        </ConfigProvider>
    </>
);

