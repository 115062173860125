import {Button, Descriptions, Drawer, Space, Table, Tag} from "antd";
import {AuditOutlined, CloseCircleFilled, DollarOutlined, PrinterOutlined} from "@ant-design/icons";
import {formatNumber, readNumber} from "../../Helpers/number-helper";
import {REQUEST_STATUS} from "./const";

import dayjs from "dayjs";
import {PaymentLineTable} from "./helper";
import FormPrintPreview from "./FormPrintPreview";
import {useState} from "react";

const RequestView = ({open, onClose, requestData}) => {

    const getItem = (key, label, value, span = null) => {
        const item =
            {
                key: key.toString(),
                label,
                children: value,
            }

        if (span) {
            item.span = span;
        }

        return item;

    }

    const user_items = [
        getItem(1, 'Mã đề nghị', requestData?.request.request_code),
        getItem(2, 'Phòng ban đề nghị', requestData?.request.request_department),
        getItem(3, 'Người đề nghị', requestData?.request.created_by_name),
        getItem(4, 'Ngày tạo', dayjs(requestData?.request.created_at).format('DD-MM-YYYY HH:mm')),
        getItem(5, 'Loại chi phí', requestData?.request.expense_type),
        getItem(6, 'Ngày cần chi', dayjs(requestData?.request.request_payment_date).format('DD-MM-YYYY')),
        getItem(7, 'Diễn giải', requestData?.request.description),
        getItem(8, 'Phân loại thanh toán', requestData?.request.instruction),
        getItem(9, 'Tài khoản đi tiền', requestData?.request.transfer_from_account),
        getItem(10, 'Số tiền', formatNumber(requestData?.request.request_amount)),
        getItem(11, 'Loại tiền', requestData?.request.currency_code),
        getItem(12, 'Tỉ giá', formatNumber(requestData?.request.exchange_rate || 1)),
        getItem(13, 'Quy đổi VNĐ', formatNumber(requestData?.request.foreign_to_vnd || requestData?.request.request_amount)),
        getItem(14, 'Số tiền bằng chữ', readNumber(requestData?.request.foreign_to_vnd || requestData?.request.request_amount), 2),
        getItem(15, 'Tên NCC/Thụ hưởng', requestData?.request.receiver_name),
        getItem(16, 'IPO/DPO/LSX/Mã đơn', requestData?.request?.special_code),
        getItem(17, 'Mùa vụ', requestData?.request?.special_season),
        getItem(18, 'Mã PO', requestData?.request?.erp_code),
    ]

    const kt_items = [
        getItem(1, 'Số tiền duyệt chi', formatNumber(requestData?.request.approved_request_amount)),
        getItem(2, 'Loại tiền', requestData?.request.approved_currency_code),
        getItem(3, 'Tỉ giá', formatNumber(requestData?.request.approved_exchange_rate || 1)),
        getItem(4, 'Quy đổi VNĐ', formatNumber(requestData?.request.approved_foreign_to_vnd || requestData?.request.approved_request_amount)),
        getItem(5, 'Số tiền bằng chữ', readNumber(requestData?.request.approved_foreign_to_vnd || requestData?.request.approved_request_amount), 2),
        getItem(6, 'Ngày xác nhận chi', dayjs(requestData?.request.approved_request_payment_date).format('DD-MM-YYYY')),
        getItem(7, 'Ghi chú', requestData?.request.approved_note, 2),
    ]

    const tc_columns = [
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: '1',
            render: (text, record) => (
                <span>{formatNumber(record.amount)}</span>
            ),
            align: 'right'
        },
        {
            title: 'Loại tiền',
            dataIndex: 'currency_code',
            key: '2',
            align: 'center'
        },
        {
            title: 'Tỉ giá',
            dataIndex: 'exchange_rate',
            key: '3',
            render: (text, record) => (
                <span>{formatNumber(record.exchange_rate)}</span>
            ),
            align: 'right'
        },
        {
            title: 'Quy đổi VNĐ',
            dataIndex: 'vnd_amount',
            key: '4',
            render: (text, record) => (
                <span>{formatNumber(record.vnd_amount)}</span>
            ),
            align: 'right'
        },
        {
            title: 'Ngày đi tiền',
            dataIndex: 'payment_date',
            key: '5',
            render: (text, record) => (
                <span>{dayjs(record.payment_date).format('DD-MM-YYYY')}</span>
            ),
            align: 'center'
        },
        {
            title: 'Tình trạng',
            dataIndex: 'status',
            key: '6',
            render: (text, record) => (
                <Tag color={record.status ? 'blue' : 'red'}>
                    {record.status ? 'Hoàn tất' : 'Đang xử lý'}
                </Tag>
            ),
            align: 'center'
        }
    ]

    const [printOpen, setPrintOpen] = useState(false)

    return (
        <Drawer
            open={open}
            onClose={onClose}
            width={"100vw"}
            extra={(
                <Space>
                    {requestData?.request?.payment_lines?.length >0 && (
                        <Button type="primary" icon={<PrinterOutlined />} onClick={() => setPrintOpen(true)}>In đề nghị</Button>
                    )}
                    <Button danger icon={<CloseCircleFilled/>} onClick={onClose}>Đóng lại</Button>
                </Space>
            )}
            title={`Xem đề nghị: ${requestData?.request.request_code}`}
        >
            <Descriptions
                layout="vertical"
                bordered
                items={user_items}
                size="middle"
            />
            <PaymentLineTable lineData={requestData?.request?.payment_lines}/>
            <Descriptions
                layout="vertical"
                bordered
                items={kt_items}
                size="middle"
                title={(
                    <div className="items-start-end"><Space><AuditOutlined/> <span>Kế toán xác nhận</span></Space><Tag
                        color={REQUEST_STATUS[requestData?.request.request_status]?.color}>{REQUEST_STATUS[requestData?.request.request_status]?.label}</Tag>
                    </div>)}
                className="mt-3"
            />
            <div className="mt-3 text-bold"><Space><DollarOutlined/> <span>Lịch chi tiền</span></Space></div>
            <Table
                bordered
                columns={tc_columns}
                className="mt-3"
                dataSource={requestData?.payments}
                pagination={false}
            />
            <FormPrintPreview data={requestData?.request} open={printOpen} setOpen={setPrintOpen}/>
        </Drawer>
    )

}

export default RequestView