import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {UseLoading} from "../../Helpers/loading-spinner-helper";
import api from "../../Helpers/api-helper";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Popover,
    Row,
    Select,
    App,
    Tag,
    Space,
    Tooltip, Dropdown, Skeleton
} from "antd";
import UserInformation from "../UserInformation";
import {calculateForeignToVND, OptionsWithDescription} from "../../Helpers/common-helper";
import {formatNumber, readNumber} from "../../Helpers/number-helper";
import {
    ArrowLeftOutlined, CheckCircleOutlined,
    CheckOutlined, DeleteOutlined,
    ExclamationCircleFilled, PlusOutlined, PrinterOutlined,
    SaveOutlined, SwapOutlined, UndoOutlined
} from "@ant-design/icons";
import {notify} from "../../Helpers/toast-helper";
import {REQUEST_STATUS} from "../PaymentRequest/const";
import {IconBank, IconCurrencyExchange, IconMoney} from "../Icons";
import {UseSiteConfig} from "../../Helpers/config-helper";
import {v4} from "uuid";

import dayjs from "dayjs";
import {PaymentLineTable} from "../PaymentRequest/helper";
import FormPrintPreview from "../PaymentRequest/FormPrintPreview";

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
/*
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Ho_Chi_Minh');

 */


const {TextArea} = Input;

/*
const inlineNumberFormat = (value, min = 0) => {
    //convert to string and add comma
    if (value === null || value === undefined || value === '')
        value = min;

    return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
 */

const inlineNumberFormat = (value, min = 0) => {
    if (value === null || value === undefined || value === '') {
        value = min;
    }

    const [integerPart, decimalPart] = `${value}`.split('.');

    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (decimalPart) {
        return `${formattedIntegerPart}.${decimalPart}`;
    } else {
        return formattedIntegerPart;
    }
}

const PaymentLines = ({lineData, lineIndex, config, onDelete, onUndo, onChange, onRecalculate}) => {

    const onCalculate = () => {
        //if exchange_rate is null, set to 1, if not null, remove comma and convert to number type
        let exchangeRate = lineData.exchange_rate || 1;
        exchangeRate = Number(typeof exchangeRate === 'string' ? exchangeRate.replace(/,/g, '') : exchangeRate);
        onRecalculate(exchangeRate);
    }

    const handleOnChange = (field, value) => {
        //check if value is number after trim, remove all comma, if not, return
        value = value.trim().replace(/,/g, '');
        if (isNaN(value)) {
            notify('error', 'Định dạng số không hợp lệ');
            return;
        }
        onChange(field, lineIndex, value)
    }

    return (
        <Row gutter={[16, 16]}
             className="mb-3"
        >
            <Col span={4}>
                <span>Số tiền chi:&nbsp;</span>
                <Tooltip title={(<div>
                    <span>Cấp nguồn bởi: {lineData?.created_by_name}</span><br/><span>Lúc: {dayjs(lineData.created_at).format('DD-MM-YYYY HH:mm:ss')}</span>
                </div>)}>
                    <ExclamationCircleFilled className="text-warning"
                                             style={{display: lineData._id ? 'inline-block' : 'none'}}/>
                </Tooltip>
                <br/>
                <InputNumber
                    prefix={<IconMoney className="opacity-50"/>}
                    className="mt-2"
                    //onChange={(value) => onChange('amount', lineIndex, value)}
                    formatter={(value) => inlineNumberFormat(value)}
                    defaultValue={lineData.amount}
                    onBlur={(e) => {
                        handleOnChange('amount', e.target.value)
                    }}
                    onPressEnter={(e) => {
                        handleOnChange('amount', e.target.value)
                    }}
                    controls={false}
                    style={{width: '100%'}}
                />
            </Col>
            <Col span={4}>
                <span>Loại tiền:</span>
                <br/>
                <Select
                    className="mt-2"
                    style={{width: '100%'}}
                    value={lineData.currency_code}
                    onChange={(value) => onChange('currency_code', lineIndex, value)}
                    optionLabelProp="value"
                >
                    {OptionsWithDescription(config?.rates, {
                        label: 'currency',
                        description: 'currency_name'
                    })}
                </Select>
            </Col>
            <Col span={3}>
                <span>Tỷ giá:</span>
                <br/>
                <InputNumber
                    prefix={<IconCurrencyExchange className="opacity-50"/>}
                    addonAfter={<Button size="small" onClick={onCalculate}
                                        title="Tự động tính số tiền VND còn lại theo tỉ giá quy đổi"
                                        icon={<SwapOutlined className="opacity-50"/>} type=""/>}
                    className="mt-2"
                    formatter={(value) => inlineNumberFormat(value)}
                    //onChange={(value) => onChange('exchange_rate', lineIndex, value)}
                    defaultValue={lineData.exchange_rate}
                    onBlur={(e) => {
                        handleOnChange('exchange_rate', e.target.value)
                    }}
                    onPressEnter={(e) => {
                        handleOnChange('exchange_rate', e.target.value)
                    }}
                    controls={false}
                    min={1}
                    style={{width: '100%'}}
                />
            </Col>
            <Col span={4}>
                <span>Quy đổi VNĐ:</span>
                <br/>
                <InputNumber
                    prefix="VND"
                    className="mt-2"
                    formatter={(value) => inlineNumberFormat(value)}
                    onChange={(value) => onChange('vnd_amount', lineIndex, value)}
                    value={lineData.vnd_amount}
                    controls={false}
                    readOnly={true}
                    style={{width: '100%'}}
                />
            </Col>

            <Col span={4}>
                <span>Ngày TC xác nhận:</span>
                <br/>
                <DatePicker
                    className="mt-2"
                    onChange={(value) => onChange('payment_date', lineIndex, value)}
                    style={{width: '100%'}}
                    value={dayjs(lineData?.payment_date)}
                    format="DD-MM-YYYY"
                    allowClear={false}
                />
            </Col>
            <Col span={4}>
                <span>Nguồn chi:</span>
                <br/>
                <Input
                    prefix={<IconBank className="opacity-50"/>}
                    className="mt-2"

                    onBlur={(e) => {
                        onChange('payment_source', lineIndex, e.target.value)
                    }}
                    onPressEnter={(e) => {
                        onChange('payment_source', lineIndex, e.target.value)
                    }}

                    style={{width: '100%'}}
                    defaultValue={lineData.payment_source}
                />
            </Col>
            <Col span={1}>
                <br/>
                {
                    lineData.status === true ?
                        (
                            <Dropdown
                                menu={{
                                    items: [{
                                        key: '1',
                                        label: (<a target="_blank" onClick={() => onUndo(lineIndex)}
                                                   rel="noopener noreferrer"><UndoOutlined/> Hoàn chi</a>)
                                    }]
                                }}
                                placement="bottomRight"
                            >
                                <Button title="Đã chi tiền" icon={<CheckCircleOutlined className="text-success"/>}
                                        type="text" className="mt-2"/>
                            </Dropdown>
                        ) :
                        (<Button
                            title="Xoá dữ liệu này"
                            className="mt-2"
                            type="text"
                            icon={<DeleteOutlined className="text-danger"/>}
                            onClick={() => onDelete(lineIndex)}
                        />)
                }
            </Col>
        </Row>
    )
}

const RenderPopOverCopy = ({label, value}) => {
    return (
        //trigger copy to clipboard when click on the value using clipboardjs
        <Popover
            content={(<span>Click để sao chép vào clipboard</span>)}
            trigger="hover"
            arrow={false}
        >
            <span
                className="text-bold link-text"
                onClick={async () => {
                    await navigator.clipboard.writeText(value);
                    notify('info', 'Đã sao chép vào clipboard', 2);
                }}
            >{label}</span>
        </Popover>
    )
}

const RenderTCSummary = ({paymentLines, formData, displayRemainAmount}) => {
    return (
        <Space>
            <span className="text-success">Tài chính xử lý</span>
            <span>|</span>
            <div>
                Cần chi:&nbsp;<span
                className="text-danger">{formatNumber(formData.approved_foreign_to_vnd)}&nbsp;VNĐ</span>
            </div>
            <span>|</span>
            <span>
                                Đã cấp nguồn:&nbsp;
                <span className="text-success">
                                {
                                    //sum of all paymentLines and add currency code ad suffix
                                    formatNumber(paymentLines.reduce((total, line) => total + line.vnd_amount, 0)) + ' VNĐ'
                                }
                                </span>
                            </span>
            <span>|</span>
            <div>
                Cần chi còn lại:&nbsp;
                {displayRemainAmount}
            </div>
        </Space>
    )
}
const ProcessPayment = () => {
    const navigate = useNavigate();
    const {modal} = App.useApp();
    const formGutter = [24, 0];
    const [form] = Form.useForm();
    //get params from react-router-dom
    const {id} = useParams();
    //use setLoading hook
    const setLoading = UseLoading(state => state.setLoading);
    const [formData, setFormData] = useState({});
    const [config, setConfig] = useState({});

    const approved_vnd = Form.useWatch('approved_foreign_to_vnd', form);

    const approved_fields = ['approved_request_amount', 'approved_currency_code', 'approved_exchange_rate', 'approved_foreign_to_vnd'];
    const approved_status = [{
        label: 'Đủ điều kiện thanh toán',
        value: 'waiting_for_process'
    }, {label: 'Chưa đủ điều kiện thanh toán', value: 'return_to_requester'}]

    const [notUserFault, setNotUserFault] = useState(true);

    const [paymentLines, setPaymentLines] = useState([]);
    const [printPreviewOpen, setPrintPreviewOpen] = useState(false);
    const [userFormData, setUserFormData] = useState(null);

    //User information like name, position, department, email, phone...
    const [user, setUser] = useState(null)

    useEffect(() => {
        if (formData?.created_by) {
            api.post('/master/user/get', {uid: formData?.created_by}).then(response => {
                if (response) {
                    //console.log(response)
                    setUser(response);
                }
            });
        }

        return () => {
            setUser(null);
        }
    }, [formData]);

    const {siteConfig} = UseSiteConfig();

    const fetchData = () => {
        setLoading(true);
        api.post(`/request/process/get`, {_id: id}).then(response => {
            if (response) {
                if (response.approved_request_payment_date) {
                    response.approved_request_payment_date = dayjs(response.approved_request_payment_date)
                }

                setFormData(response);
                //check response data for any approved values, if none, apply default values
                //only apply default values if request_status other than 'new' or 'return_to_requester'
                if (response.request_status !== REQUEST_STATUS.new.code && response.request_status !== REQUEST_STATUS.return_to_requester.code) {
                    approved_fields.forEach(field => {
                        if (!response[field]) {
                            response[field] = formatNumber(response[field.replace('approved_', '')]);
                        }
                    })
                }

                form.setFieldsValue(response);

                //if request_status is 'partial_payment' or 'full_payment', get payments data from server
                //if (response.request_status === 'partial_payment' || response.request_status === 'full_payment') {
                api.post(`/request/process/payment/get`, {_id: id}).then(resp => {
                    if (resp) {
                        setPaymentLines(resp);
                    }
                })
                //}

            }
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        //get data from server
        if (id) {
            setConfig(siteConfig);
            fetchData();
        }
    }, [id]);

    const handleRequestAmountChange = (value) => {
        const currencyCode = form.getFieldValue('approved_currency_code');
        const exchangeRate = form.getFieldValue('approved_exchange_rate') || 1;
        const approvedAmount = form.getFieldValue('approved_request_amount') || 0

        if (currencyCode && exchangeRate) {
            const convertedAmount = calculateForeignToVND(approvedAmount, exchangeRate);

            form.setFieldsValue({
                approved_foreign_to_vnd: convertedAmount
            })
        }
    }

    const FormExtra = ({formStatus}) => {
        const handleAccept = () => {
            modal.confirm({
                title: 'Xác nhận tiếp nhận đề nghị để xử lý',
                icon: <ExclamationCircleFilled/>,
                content: (
                    <span>Có chắc chắn tiếp nhận đề nghị <strong>{formData.request_code}</strong> này không?</span>),
                okText: 'Đồng ý',
                okType: 'danger',
                cancelText: 'Bỏ qua',
                onOk: () => {
                    setLoading(true);
                    api.post(`/request/process/accept`, {_id: formData._id}).then(resp => {
                        if (resp) {
                            notify('success', resp);
                            navigate('/request/2');
                        }
                    }).finally(() => {
                        setLoading(false)
                    })
                },
                onCancel() {
                },
            });
        }

        if (formStatus === 'new') {
            return (
                <Button type="primary" danger size="small" onClick={handleAccept} icon={<CheckOutlined/>}>Tiếp nhận xử
                    lý</Button>
            )
        }

        return (
            <Tag
                //find status in REQUEST_STATUS object keys and get the color
                color={REQUEST_STATUS[formStatus]?.color}
            >
                {REQUEST_STATUS[formStatus]?.label}
            </Tag>
        );
    }

    const CardTitle = ({title}) => {
        return (
            <Space>
                <Button type="text" title="Quay lại danh mục" icon={<ArrowLeftOutlined/>}
                        onClick={() => navigate(-1)}/>
                <span>{title}</span>
            </Space>
        )
    }

    const handleFormSubmit = () => {
        form.validateFields().then(values => {
            if (values?.approval_status === REQUEST_STATUS.waiting_for_process.code && !values?.approved_request_payment_date) {
                notify('error', 'Vui lòng chọn ngày yêu cầu chi');
                return;
            }

            setLoading(true);

            if (!values?.approved_exchange_rate)
                values.approved_exchange_rate = 1;

            //if approved_currency_code is VND, set approved exchange rate to 1 and set approved foreign_to_vnd equal to approved request_amount
            if (values?.approved_currency_code === 'VND') {
                values.approved_exchange_rate = 1;
                //remove comma and convert values.approved_request_amount to number type
                values.approved_foreign_to_vnd = values.approved_request_amount;
            }

            //Check if approved_request_amount is string, remove comma and convert to number type
            values.approved_request_amount = Number(typeof values.approved_request_amount === 'string' ? values.approved_request_amount.replace(/,/g, '') : values.approved_request_amount);
            values.approved_foreign_to_vnd = Number(typeof values.approved_foreign_to_vnd === 'string' ? values.approved_foreign_to_vnd.replace(/,/g, '') : values.approved_foreign_to_vnd);

            //if values?.approval_status is 'return_to_requester', set approved values to null
            if (values?.approval_status === REQUEST_STATUS.return_to_requester.code) {
                approved_fields.forEach(field => {
                    values[field] = null;
                })
            }

            api.post(`/request/process/update`, {...values, _id: formData._id}).then(resp => {
                if (resp) {
                    notify('success', resp);
                    navigate('/request/2');
                }
            }).finally(() => {
                setLoading(false)
            })
        }).catch(error => {
            return;
        })
    }


    const handleAddPaymentLine = () => {
        const currentLineCount = paymentLines.length;
        //if currentLineCount === 0, get default values from formData

        setPaymentLines([...paymentLines, {
            amount: currentLineCount === 0 ? formData.approved_request_amount : 0,
            currency_code: currentLineCount === 0 ? formData.approved_currency_code : 'VND',
            exchange_rate: currentLineCount === 0 ? formData.approved_exchange_rate || 1 : 1,
            vnd_amount: currentLineCount === 0 ? formData.approved_foreign_to_vnd : 0,
            payment_date: dayjs(),
            payment_source: '',
            status: false
        }]);
    }

    const handleTCSubmit = () => {
        const giveFunds = () => {
            //convert amount, vnd_amount to number type if they are string
            paymentLines.forEach((line, index) => {
                paymentLines[index].amount = Number(typeof line.amount === 'string' ? line.amount.replace(/,/g, '') : line.amount);
                paymentLines[index].vnd_amount = Number(typeof line.vnd_amount === 'string' ? line.vnd_amount.replace(/,/g, '') : line.vnd_amount);
            });

            //delete paymentLines item if status === true
            const filteredPaymentLines = paymentLines.filter(line => line.status === false);

            //if filteredPaymentLines length === 0, return
            if (filteredPaymentLines.length === 0) {
                notify('info', 'Không có gì để cập nhật')
                return;
            }

            setLoading(true);
            api.post(`/request/process/payment`, {_id: formData._id, data: filteredPaymentLines}).then(resp => {
                if (resp) {
                    notify('success', resp);
                    navigate('/request/2');
                }
            }).finally(() => {
                setLoading(false)
            })
        }

        //calculate total amount by sum all vnd_amount and then compare with approved_foreign_to_vnd, if greater, show error
        const totalAmount = paymentLines.reduce((total, line) => total + line.vnd_amount, 0);
        if (totalAmount > formData.approved_foreign_to_vnd) {
            notify('error', 'Tổng số tiền vượt quá số tiền được xác nhận chi');
            setLoading(false);
            return;
        }

        //If total vnd_amount less than approved_foreign_to_vnd, show error and return
        if (totalAmount < formData.approved_foreign_to_vnd) {
            //notify('error', 'Tổng số tiền cấp nguồn chưa đủ so với yêu cầu chi');
            //display a promise confirm modal, if user click ok, continue, else return
            modal.confirm({
                title: 'Xác nhận cấp nguồn',
                icon: <ExclamationCircleFilled/>,
                content: (
                    <span>Tổng số tiền cấp nguồn chưa đủ so với yêu cầu chi, bạn có chắc chắn cấp nguồn cho đề nghị này không?</span>),
                okText: 'Đồng ý cấp nguồn',
                okType: 'danger',
                cancelText: 'Bỏ qua',
                onOk: () => {
                    giveFunds();
                },

            });
        } else {
            giveFunds()
        }
    }

    const handleStatusChange = (value) => {
        if (value === 'waiting_for_process') {
            //update default values from formData to KT Form

            let approved_values = {}
            approved_fields.forEach(field => {
                if (!formData[field]) {
                    approved_values[field] = formatNumber(formData[field.replace('approved_', '')]);
                } else {
                    approved_values[field] = formData[field];
                }
            })

            form.setFieldsValue({
                ...approved_values,
            })

            //Set user_fault to unchecked
            form.setFieldsValue({user_fault: false})
            setNotUserFault(false);

        } else if (value === 'return_to_requester') {
            //set all approved values to null
            let approved_values = {
                approved_request_payment_date: null
            }
            approved_fields.forEach(field => {
                approved_values[field] = null;
            });

            form.setFieldsValue(approved_values)
            setNotUserFault(true)
        }
    }

    const displayRemainAmount = useMemo(() => {
        if (paymentLines.length === 0)
            return <span className="text-info">{formatNumber(formData.approved_foreign_to_vnd) + ' VNĐ'}</span>

        let remaining = formData.approved_foreign_to_vnd - paymentLines.reduce((total, line) => total + line.vnd_amount, 0);
        //if remaining < 0, set span text color to danger, else color is text-info
        if (remaining < 0) {
            return <span
                className="text-danger text-blinking">{formatNumber(remaining) + ' VNĐ'}</span>
        } else {
            return <span
                className="text-info">{formatNumber(remaining) + ' VNĐ'}</span>
        }
    }, [paymentLines, formData]);


    return (
        <div>
            <Form
                layout="vertical"
                form={form}
            >
                <Card
                    title={<CardTitle title="Thông tin người đề nghị nhập"/>}
                    extra={<FormExtra formStatus={formData.request_status}/>}
                    size="small"
                >
                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <span className="text-bold text-muted">Mã đề nghị:</span>
                            <br/>
                            <span className="text-bold text-danger">{formData.request_code}</span>
                        </Col>
                        <Col span={6}>
                            <span className="text-bold text-muted">Người đề nghị: </span>
                            <Popover content={(<UserInformation user={user}/>)}
                                     title="Thông tin người đề nghị"
                                     trigger="hover">
                                <span className="text-bold link-text">{formData.created_by_name}</span>
                            </Popover>
                            <br/>
                            <span>{user?.company ?? <Skeleton.Input active size="small"/>}</span>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="request_department"
                                label={(<span className="text-bold text-muted">Phòng ban đề nghị:</span>)}
                            >
                                <Select
                                    style={{width: '100%'}}
                                    disabled={formData.role !== 'kt'}
                                    optionLabelProp="value"
                                >
                                    {OptionsWithDescription(config.department, {label: 'name', description: null})}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6} className="text-end">
                            <span className="text-bold text-muted">Ngày tạo:</span>
                            <br/>
                            <span className="text-bold">{dayjs(formData.created_at).format("DD-MM-YYYY HH:ss")}</span>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <Form.Item
                                name="expense_type"
                                label={(<span className="text-bold text-muted">Loại chi phí:</span>)}
                            >
                                <Select
                                    disabled={formData.role !== 'kt'}
                                    optionLabelProp="value"
                                >
                                    {OptionsWithDescription(config.expense_type, {
                                        label: 'name',
                                        description: 'description'
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-muted">Ngày cần chi:</span>)}
                            >
                                <span>{dayjs(formData.request_payment_date).format('DD-MM-YYYY')}</span>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-muted">Diễn giải:</span>)}
                            >
                                <span>{formData.description}</span>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-muted">Phân loại thanh toán:</span>)}
                            >
                                <span>{formData.instruction}</span>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <span className="text-bold text-muted">Mã PO ERP:</span>
                            <br/>
                            <span className="text-bold text-danger">{formData.erp_code}</span>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-muted">IPO/DPO/LSX/Mã đơn:</span>)}
                            >
                                <span>{formData?.special_code || "-"}</span>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-muted">Mùa vụ:</span>)}
                            >
                                <span>{formData?.special_season || "-"}</span>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-muted">Tài khoản đi tiền</span>)}
                                name="transfer_from_account"
                            >
                                <Select
                                    disabled={formData.role !== 'kt'}
                                    optionLabelProp="value"
                                >
                                    {OptionsWithDescription(config.transfer_from_account, {
                                        label: 'name',
                                        description: 'description'
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-muted">Số tiền:</span>)}
                            >
                                <RenderPopOverCopy label={formatNumber(formData.request_amount)}
                                                   value={formData.request_amount}/>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-muted">Loại tiền:</span>)}
                            >
                                <span>{formData.currency_code}</span>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-muted">Tỉ giá tạm tính:</span>)}
                            >
                                <RenderPopOverCopy
                                    label={formatNumber(formData.exchange_rate)}
                                    value={formData.exchange_rate}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-muted">Quy đổi VNĐ:</span>)}
                            >
                                <span>{formatNumber(formData.foreign_to_vnd)}</span>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-muted">Tên tài khoản nhận tiền:</span>)}
                            >
                                <span>{formData.receiver_name}</span>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={(
                                    <span className="text-bold text-muted">Số tài khoản:</span>
                                )}
                            >
                                {formData?.bank_account}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={(
                                    <span className="text-bold text-muted">Ngân hàng:</span>
                                )}
                            >
                                <Space>
                                    {/*get bank logo and bank description from siteConfig.bank_list matching bank_name*/}
                                    <img src={siteConfig.bank_list?.find(bank => bank.value === formData.bank_name)?.logo} height="30"/>
                                    <span>{formData?.bank_name}<br />{siteConfig.bank_list?.find(bank => bank.value === formData.bank_name)?.description}</span>
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={(
                                    <span className="text-bold text-muted">Tỉnh/Thành phố: {formData?.bank_province}</span>
                                )}
                            >
                                <Space direction="vertical">
                                    <span>Chi nhánh: {formData?.bank_branch}</span>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <PaymentLineTable lineData={form.getFieldValue('payment_lines')} showPrintButton={(
                    <Button
                        size="small"
                        icon={<PrinterOutlined />}
                        onClick={() => setPrintPreviewOpen(true)}
                    />
                )}/>

                <Card className="mt-3"
                      title={<span className="text-danger">Kế toán xử lý</span>}
                      size="small"
                >
                    <Row gutter={formGutter}>
                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-danger">Số tiền xác nhận:</span>)}
                                name="approved_request_amount"
                            >
                                <InputNumber
                                    prefix={<IconMoney className="opacity-50"/>}
                                    onChange={handleRequestAmountChange}
                                    controls={false}
                                    formatter={(value) => inlineNumberFormat(value)}
                                    style={{width: '100%'}}
                                    disabled={formData.role !== 'kt'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng nhập số tiền xác nhận chi'
                                        }]}
                                />

                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-danger">Loại tiền:</span>)}
                                name="approved_currency_code"
                                /*
                                initialValue={
                                    (formData.request_status !== REQUEST_STATUS.new.code || REQUEST_STATUS.return_to_requester.code) ?
                                    formData.currency_code : null
                                }
                                 */
                            >
                                <Select
                                    disabled={formData.role !== 'kt'}
                                    optionLabelProp="value"
                                >
                                    {OptionsWithDescription(config?.rates, {
                                        label: 'currency',
                                        description: 'currency_name'
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-danger">Tỉ giá tạm tính:</span>)}
                                name="approved_exchange_rate"
                            >
                                <InputNumber
                                    prefix={<IconCurrencyExchange className="opacity-50"/>}
                                    onChange={handleRequestAmountChange}
                                    controls={false}
                                    formatter={(value) => inlineNumberFormat(value, 1)}
                                    min={1}
                                    style={{width: '100%'}}
                                    disabled={formData.role !== 'kt'}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label={(<span className="text-bold text-danger">Quy đổi VNĐ:</span>)}
                                name="approved_foreign_to_vnd"
                            >
                                <InputNumber
                                    prefix="VND"
                                    controls={false}
                                    formatter={(value) => inlineNumberFormat(value)}
                                    style={{width: '100%'}}
                                    disabled={true}

                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={formGutter}>
                        <Col span={12}>
                            <Row gutter={[16, 0]}>
                                <Col span={16}>
                                    <Form.Item
                                        label="Cập nhật tình trạng xử lý"
                                        name="approval_status"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Vui lòng chọn tình trạng xử lý'
                                            }]}
                                    >
                                        <Select
                                            disabled={formData.role !== 'kt'}
                                            onChange={handleStatusChange}
                                            optionLabelProp="label"
                                        >
                                            {OptionsWithDescription(approved_status, {
                                                label: 'label',
                                                value: 'value'
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={8} className="text-end">
                                    <Form.Item
                                        name="user_fault"
                                        label="&nbsp;"
                                        initialValue={false}
                                    >
                                        <Select>
                                            <Select.Option value={false}>Hợp lệ</Select.Option>
                                            <Select.Option value="Chứng từ thiếu">Chứng từ thiếu</Select.Option>
                                            <Select.Option value="Chứng từ sai">Chứng từ sai</Select.Option>
                                            <Select.Option value="Chứng từ sai và thiếu">Chứng từ sai và thiếu</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item
                                label="Ngày kế toán xác nhận chi"
                                name="approved_request_payment_date"
                            >
                                <DatePicker
                                    style={{width: '100%'}}
                                    placeholder="Chọn ngày kế toán xác nhận chi"
                                    format="DD-MM-YYYY"
                                    disabled={formData.role !== 'kt'}
                                    disabledDate={(current) => {
                                        // Can not select days before today
                                        return current && current < dayjs().startOf('day');
                                    }}
                                />
                            </Form.Item>

                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Ghi chú"
                                name="approval_note"
                            >
                                <TextArea
                                    showCount
                                    maxLength={1000}
                                    disabled={formData.role !== 'kt'}
                                    style={{
                                        height: 125,
                                        resize: 'none',
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="items-start-end mt-3">
                        <div>
                            <span className="text-bold">Số tiền: </span>
                            <span className="text-bold text-danger">{readNumber(approved_vnd || 0)}</span>
                        </div>
                        <Button
                            style={{display: formData.role !== 'kt' ? 'none' : 'inline-block'}}
                            icon={<SaveOutlined/>}
                            type="primary"
                            onClick={handleFormSubmit}
                            disabled={formData.request_status === REQUEST_STATUS.finished.code}
                        >Kế toán cập nhật</Button>
                    </div>
                </Card>

                <Card
                    size="small"
                    className="mt-2"
                    title={
                        <RenderTCSummary formData={formData} displayRemainAmount={displayRemainAmount}
                                         paymentLines={paymentLines}/>
                    }
                    style={{display: ((formData.request_status === REQUEST_STATUS.new.code) || (formData.request_status === REQUEST_STATUS.return_to_requester.code)) ? 'none' : 'block'}}
                    extra={<Button
                        type="text"
                        onClick={handleAddPaymentLine}
                        icon={<PlusOutlined className="text-danger"/>}
                        disabled={formData.request_status === REQUEST_STATUS.finished.code}
                    />}
                >
                    {
                        paymentLines.map((line, index) => <PaymentLines
                            key={v4()}
                            lineData={line}
                            lineIndex={index}
                            config={config}
                            onDelete={(index) => {
                                const doDelete = () => {
                                    let newLines = [...paymentLines];
                                    newLines.splice(index, 1);
                                    setPaymentLines(newLines);
                                }

                                //if paymentLines[index] has _id, send delete request to server
                                if (paymentLines[index]._id) {
                                    //popup confirm modal to confirm delete
                                    modal.confirm({
                                        title: 'Xoá dữ liệu',
                                        icon: <ExclamationCircleFilled/>,
                                        content: (
                                            <span>Có chắc chắn xoá dòng này không? Dữ liệu bị xóa sẽ không thể phục hồi</span>),
                                        okText: 'Đồng ý xoá',
                                        okType: 'danger',
                                        cancelText: 'Bỏ qua',
                                        onOk: () => {
                                            setLoading(true);
                                            api.post(`/request/process/payment/delete`, {_id: paymentLines[index]._id}).then(resp => {
                                                if (resp && resp === 'success') {
                                                    //notify('success', resp);
                                                    //remove paymentLines[index] from array
                                                    doDelete()
                                                }
                                            }).finally(() => {
                                                setLoading(false);
                                            })
                                        },
                                        onCancel() {
                                        },
                                    });

                                } else {
                                    doDelete();
                                }
                            }}
                            onRecalculate={(exchangeRate) => {
                                //Calculate remaining amount
                                let remaining = formData.approved_foreign_to_vnd - paymentLines.reduce((total, line) => total + line.vnd_amount, 0);
                                //Check if remaining is greater than formData.approved_foreign_to_vnd if true, return error
                                if (remaining < 0) {
                                    notify('error', 'Tổng số tiền cấp nguồn vượt quá số tiền được xác nhận chi');
                                    return;
                                }

                                //Calculate foreign amount by multiplying remaining amount with exchange rate, get 2 precision
                                let foreignAmount = (remaining / exchangeRate).toFixed(4);
                                //Apply new foreign amount to current paymentLine
                                let newLines = paymentLines.map((line, lineIndex) => {
                                    //if lineIndex === index, change the key value
                                    if (lineIndex === index) {
                                        line.exchange_rate = exchangeRate;
                                        line.vnd_amount = remaining;
                                        line.amount = foreignAmount;
                                    }
                                    return line;
                                });
                                setPaymentLines(newLines)

                            }}
                            onChange={(key, index, value) => {
                                //change existing paymentLines based on prop's key and array index
                                let newLines = paymentLines.map((line, lineIndex) => {
                                    //if lineIndex === index, change the key value
                                    if (lineIndex === index) {
                                        line[key] = value;
                                        //if key is amount, exchange_rate, or payment_date, calculate vnd_amount
                                        if (['amount', 'exchange_rate', 'payment_date'].includes(key)) {
                                            line.vnd_amount = calculateForeignToVND(line.amount, line.exchange_rate);
                                        }
                                    }
                                    return line;
                                });

                                setPaymentLines(newLines);
                            }}
                            onUndo={(index) => {
                                if (paymentLines[index]._id) {
                                    //popup confirm modal to confirm delete
                                    modal.confirm({
                                        title: 'Xác nhận hoàn chi',
                                        icon: <ExclamationCircleFilled/>,
                                        content: (
                                            <span>Có chắc chắn hoàn chi khoản này không?</span>),
                                        okText: 'Đồng ý',
                                        okType: 'danger',
                                        cancelText: 'Bỏ qua',
                                        onOk: () => {
                                            setLoading(true);
                                            api.post(`/request/process/payment/undo`, {_id: paymentLines[index]._id}).then(resp => {
                                                if (resp && resp === 'success') {
                                                    notify('success', 'Hoàn chi thành công');
                                                    fetchData()
                                                } else {
                                                    notify('error', resp);
                                                }
                                            }).finally(() => {
                                                setLoading(false);
                                            })
                                        },
                                        onCancel() {
                                        },
                                    });
                                }
                            }}
                        />)
                    }
                    <div className="text-end mt-5">
                        <Button
                            icon={<SaveOutlined/>}
                            type="primary"
                            disabled={formData.request_status === REQUEST_STATUS.finished.code}
                            onClick={handleTCSubmit}
                        >
                            Tài chính cập nhật
                        </Button>
                    </div>
                </Card>
            </Form>

            <FormPrintPreview
                data={formData}
                open={printPreviewOpen}
                setOpen={setPrintPreviewOpen}
                lineData={paymentLines}
            />
        </div>
    );
}

export default ProcessPayment;