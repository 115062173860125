import api from "./api-helper";

import { createWithEqualityFn } from 'zustand/traditional';
import {provinces} from "./enum";
export const TEST = false;
export const UseSiteConfig = createWithEqualityFn((set) => ({
    siteConfig: {},
    setConfig: (config) => set(() => ({siteConfig: {...config}}))
}))

export const getSiteConfig = () => {
    return new Promise( (resolve, reject) => {
        const useConfig = UseSiteConfig.getState();

        api.get('/master/get')
            .then(async (response) => {
                if (response) {
                    try {
                        const bank_list = await fetch('https://api.vietqr.io/v2/banks', {method: 'GET'}).then((res) => res.json());

                        response.bank_list = bank_list.data.map((bank) => ({
                            id: bank.id,
                            key: bank.id,
                            value: bank.name,
                            description: `${bank.code} - ${bank.shortName}`,
                            logo: bank.logo
                        }))

                        //Get max value of id from bank_list
                        const max_id = Math.max(...response.bank_list.map((bank) => bank.id));

                        //Add Kho bac Nha Nuoc to the list
                        response.bank_list.push({
                            id: max_id + 1,
                            key: max_id + 1,
                            value: 'Kho bạc Nhà Nước',
                            description: 'Kho bạc Nhà Nước',
                            logo: 'https://i.postimg.cc/5NSw-Y7dr/kho-bac-nha-nuoc-cong-bo-bieu-trung-moi-02-7823.jpg'
                        },
                            {
                                id: max_id + 2,
                                key: max_id + 2,
                                value: 'Ngân hàng nước ngoài',
                                description: 'Ngân hàng nước ngoài',
                                logo:'https://i.postimg.cc/CLBVqYkc/global-logo.png'
                            }

                        );

                        //Add provinces to the config

                        response.province = provinces
                    } catch (error) {
                        console.log('Error getting bank list from API:', error.message);
                        resolve({});
                    }

                    useConfig.setConfig(response);
                    resolve(response);
                } else {
                    resolve({});
                }
            })
            .catch((error) => {
                resolve({});
            });
    });
};