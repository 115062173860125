import {Button, Col, Flex, Form, InputNumber, Modal, Row, Space} from "antd";
import {
    BorderBottomOutlined,
    BorderInnerOutlined,
    BorderLeftOutlined,
    BorderRightOutlined,
    BorderTopOutlined, CheckOutlined, CloseOutlined
} from "@ant-design/icons";
import {notify} from "../../Helpers/toast-helper";
import {useEffect} from "react";

const PageMargin = ({open, setOpen, margins, setMargins, defaultMargins}) => {
    const [form] = Form.useForm()

    const handleClose = () => {
        setOpen(false)
        form.resetFields()
    }

    const handleSetMargin = () => {
        form.validateFields().then(values => {
            console.log(values)
            setMargins(values)
            handleClose()
        }).catch(error => {
            notify('error', 'Vui lòng nhập đầy đủ thông tin')
        })
    }

    const resetMargin = () => {
        setMargins(defaultMargins)
    }

    useEffect(() => {
        form.setFieldsValue(margins)
    }, [margins])

    return (
        <Modal
            centered
            title="Căn lề trang in"
            open={open}
            onCancel={handleClose}
            width={500}
            footer={
                <Flex
                    justify={'space-between'}
                >
                    <Button
                        icon={<BorderInnerOutlined />}
                        onClick={resetMargin}
                    >
                        Mặc định
                    </Button>
                    <Space>
                        <Button
                            onClick={handleClose}
                            icon={<CloseOutlined />}
                        >
                            Bỏ qua
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleSetMargin}
                            icon={<CheckOutlined />}
                        >
                            Đồng ý
                        </Button>
                    </Space>
                </Flex>
            }
        >
            <Form
                form={form}
                requiredMark={false}
            >
                <Row gutter={[16, 0]} className="mt-4">
                    <Col span={12}>
                        <Form.Item
                            name='top'
                            label='Lề trên'
                            initialValue={margins?.top}
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập lề trên'
                                }
                            ]}
                        >
                            <InputNumber
                                addonAfter='cm'
                                addonBefore={<BorderTopOutlined/>}
                                step={0.1}
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name='bottom'
                            label='Lề dưới'
                            initialValue={margins?.bottom}
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập lề dưới'
                                }
                            ]}
                        >
                            <InputNumber
                                addonAfter='cm'
                                addonBefore={<BorderBottomOutlined/>}
                                step={0.1}
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 0]}>
                    <Col span={12}>
                        <Form.Item
                            name='left'
                            label='Lề trái'
                            initialValue={margins?.left}
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập lề trái'
                                }
                            ]}
                        >
                            <InputNumber
                                addonAfter='cm'
                                addonBefore={<BorderLeftOutlined/>}
                                step={0.1}
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name='right'
                            label='Lề phải'
                            initialValue={margins?.right}
                            rules={[
                                {
                                    required: true,
                                    message: 'Vui lòng nhập lề phải'
                                }
                            ]}
                        >
                            <InputNumber
                                addonAfter='cm'
                                addonBefore={<BorderRightOutlined/>}
                                step={0.1}
                                min={0}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default PageMargin;